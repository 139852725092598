import React, { Component } from 'react'
import { photoswipeItemsFromPhotos, indexOfPhotoId } from '../../selectors/PhotosSelectors'
import { array } from 'prop-types'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import customPhotoswipe from './customPhotoswipe'
import { findIndex } from 'lodash-es'
import PhotoSwipe from 'photoswipe'

const withPhotoswipe = WrappedComponent => {
  return class withPhotoswipe extends Component {
    propTypes = {
      photos: array.isRequired
    }

    openPhotoswipeGallery = (photoId, photos) => {
      const pswpElement = document.querySelectorAll('.pswp')[0];
      photos = photos || this.props.photos
      let index = 0;
      if (Array.isArray(photos)) {
        index = indexOfPhotoId(photos, photoId)
      } else {
        photos = Object.keys(photos).map(photoId => photos[photoId])
        index = indexOfPhotoId(photos, photoId)
      }
      const options = { 
        index: photoId ? indexOfPhotoId(photos, photoId) : 0, 
        shareButtons: [
          {id:'sharable-link', label:'Share photo', url:'{{text}}', download: false},
          {id:'download', label:'Download photo', url:'{{raw_image_url}}', download: true}
        ]
      };
      let gallery = new PhotoSwipe(pswpElement, customPhotoswipe, photoswipeItemsFromPhotos(photos), options);
      gallery.init();
    }

    _renderPhotoswipeBoilerplate = () => {
      return (
        <div className="pswp" tabindex="-1" role="dialog" aria-hidden="true">
          <div className="pswp__bg"></div>
          <div className="pswp__scroll-wrap">
            <div className="pswp__container">
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
              <div className="pswp__item"></div>
            </div>
            <div className="pswp__ui pswp__ui--hidden">
              <div className="pswp__top-bar">
                <div className="pswp__counter"></div>
                <button className="pswp__button pswp__button--close" title="Close (Esc)"></button>
                <button className="pswp__button pswp__button--share" title="Share"></button>
                <button className="pswp__button pswp__button--fs" title="Toggle fullscreen"></button>
                <button className="pswp__button pswp__button--zoom" title="Zoom in/out"></button>
                <div className="pswp__preloader">
                  <div className="pswp__preloader__icn">
                    <div className="pswp__preloader__cut">
                      <div className="pswp__preloader__donut"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                <div className="pswp__share-tooltip">
                  Testing
                </div> 
              </div>
              <button className="pswp__button pswp__button--arrow--left" title="Previous (arrow left)">
              </button>
              <button className="pswp__button pswp__button--arrow--right" title="Next (arrow right)">
              </button>
              <div className="pswp__caption">
                <div className="pswp__caption__center"></div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    render = () => {
      return (
        <div>
          { this._renderPhotoswipeBoilerplate() }
          <WrappedComponent openPhotoswipeGallery={this.openPhotoswipeGallery} { ...this.props }/>
        </div>
      )
    }
  }
}

export default withPhotoswipe
