import queryString from 'qs';

const copyUrlToClipboard = (url) => {
    if (!url) {
        let search = window.location.search;
        const albumId = queryString.parse(search).albumId || queryString.parse(search)['?albumId']
        url = 'https://rgbgalleries.com' + window.location.pathname;
        if (albumId) {
            url += `?albumId=${albumId}`
        }
    }
    var textarea = document.createElement('textarea');
    textarea.textContent = url;
    document.body.appendChild(textarea);

    var selection = document.getSelection();
    var range = document.createRange();
    range.selectNode(textarea);
    selection.removeAllRanges();
    selection.addRange(range);

    console.log('copy success', document.execCommand('copy'));
    selection.removeAllRanges();

    document.body.removeChild(textarea);
}

export default copyUrlToClipboard;