import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import { authUser, signOutUser } from "./libs/awsLib";
import withPhotoswipe from './components/shared/withPhotoswipe'
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      activeItem: 'photos'
    };
  }

  async componentDidMount() {
    try {
      if (!this.props.match.params.username) {
        if (await authUser()) {
          this.userHasAuthenticated(true);
        }
      }
    }
    catch(e) {
      this.props.history.push("/_/login")
    }

    this.setState({ isAuthenticating: false });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name })
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = event => {
    signOutUser();
    this.userHasAuthenticated(false);
    this.props.history.push("/_/login");
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      openPhotoswipeGallery: this.props.openPhotoswipeGallery
    };

    return (
      !this.state.isAuthenticating &&
      <div className="rgbgalleries root">
        <Routes childProps={childProps} />
      </div>
    );
  }
}

export default withRouter(withPhotoswipe(App));
