export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  MAX_ALBUM_TITLE_LENGTH: 40,
  MAX_ALBUM_DESCRIPTION_LENGTH: 10000,
  PHOTO_XS_WIDTH: 150,
  s3: {
    BUCKET: "rgbgalleries-prod-temp-photos"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://813zs7txof.execute-api.us-east-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_aTiujdz1Z",
    APP_CLIENT_ID: "5dlmit97o57gglrcjcfs4t25qv",
    IDENTITY_POOL_ID: "us-east-1:0c72b791-434b-42fa-9e42-df565b41f081"
  }
};
