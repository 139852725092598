import { uniq, uniqBy, flatMap, filter, includes, findIndex, groupBy } from "lodash-es"

const albumNamesFromPhotos = photos => uniq(flatMap(photos, _ => _.albumNames || []))
const metadataTagsFromPhotos = photos => uniq(flatMap(photos, _ => _.metadataTags || []))
const photosFromMetadataTag = (photos, metadataTag) => filter(photos, _ => includes(_.metadataTags, metadataTag)) 
const photosFromAlbumName = (photos, albumName) => filter(photos, _ => includes(_.albumNames, albumName)) 
const photoswipeItemsFromPhotos = photos => flatMap(photos, _ => ({ 
														src: _.filepaths.lg, 
														w: _.width, 
														h: _.height, 
														title: _.caption,
														pid: _.photoId
													}))
const photosWithLocation = photos => filter(photos, _ => (_.geoLocation && _.geoLocation.coordinates))
const leafletMarkersFromPhotos = photos => uniqBy(
		flatMap(photosWithLocation(photos), _ => ({ ..._.geoLocation.coordinates, options: { alt: _.geoLocation.placeId }})),
		_ => _.options.alt
)
const photoFromPhotos = (photos, photoId) => photos[indexOfPhotoId(photos, photoId)]
const placeIdToPhotos = photos => groupBy(photosWithLocation(photos), _ => _.geoLocation.placeId)
const indexOfPhotoId = (photos, photoId) => findIndex(photos, _ => _.photoId === photoId)
const photosFromalbumId = (photos, albumId, albums) => {
	const album = albums.filter(album => album.albumId === albumId)[0];
	return flatMap(album.photos, photoId => photoFromPhotos(photos, photoId))
}
const photosToPhotoIdList = photos => flatMap(photos, _ => _.photoId)

const firstInstanceOfCoordinatesInPhotos = photos => {
	let coordinates;
	photos.forEach(photo => {
		if (photo.geoLocation && photo.geoLocation.coordinates) {
			coordinates = photo.geoLocation.coordinates;
		}
	})
	return coordinates
}

export { 
	metadataTagsFromPhotos, 
	albumNamesFromPhotos,
	photosFromMetadataTag,
	photosFromAlbumName,
	photoswipeItemsFromPhotos, 
	indexOfPhotoId, 
	leafletMarkersFromPhotos,
	photosWithLocation,
	placeIdToPhotos,
	photoFromPhotos,
	photosFromalbumId,
	photosToPhotoIdList,
	firstInstanceOfCoordinatesInPhotos
}