import React from 'react'
import { Route, Switch } from 'react-router-dom'
import AuthenticatedRoute from './components/AuthenticatedRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import asyncComponent from "./components/AsyncComponent";
import Loadable from 'react-loadable'
import RouteLoadingComponent from './components/RouteLoadingComponent'

const LandingPage = Loadable({
  loader: () => import('./components/landingPage/LandingPage'),
  loading: RouteLoadingComponent
});
const EditProfile = Loadable({
  loader: () => import('./components/profile/edit/EditProfile'),
  loading: RouteLoadingComponent
});
const EditAlbum = Loadable({
  loader: () => import('./components/profile/albums/edit/EditAlbum'),
  loading: RouteLoadingComponent
});
const ManageAlbums = Loadable({
  loader: () => import('./components/profile/albums/manage/ManageAlbums'),
  loading: RouteLoadingComponent
});
const NotFound = Loadable({
  loader: () => import('./components/notFound/NotFound'),
  loading: RouteLoadingComponent
});
const Login = Loadable({
  loader: () => import('./components/login/Login'),
  loading: RouteLoadingComponent
});
const AlbumViewer = Loadable({
  loader: () => import('./components/galleries/album/AlbumViewer'),
  loading: RouteLoadingComponent
});
const Signup = Loadable({
  loader: () => import('./components/signup/Signup'),
  loading: RouteLoadingComponent
});

// const LandingPage = asyncComponent(() => import('./components/landingPage/LandingPage'));
// const EditProfile = asyncComponent(() => import('./components/profile/edit/EditProfile'));
// const EditAlbum = asyncComponent(() => import('./components/profile/albums/edit/EditAlbum'));
// const ManageAlbums = asyncComponent(() => import('./components/profile/albums/manage/ManageAlbums'));
// const NotFound = asyncComponent(() => import('./components/notFound/NotFound'));
// const Login = asyncComponent(() => import('./components/login/Login'));
// const AlbumViewer = asyncComponent(() => import('./components/galleries/album/AlbumViewer'));
// const Signup = asyncComponent(() => import('./components/signup/Signup'));

export default ({ childProps }) =>
  <Switch>
    <UnauthenticatedRoute path='/_/login' exact component={Login} props={childProps} />
    <UnauthenticatedRoute path='/_/signup' exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path='/' exact component={LandingPage} props={childProps} />
    <UnauthenticatedRoute path='/:username' exact component={AlbumViewer} props={childProps} />

    <AuthenticatedRoute path='/_/profile' exact component={ManageAlbums} props={childProps} />
    <AuthenticatedRoute path='/_/profile/albums' exact component={ManageAlbums} props={childProps} />
    <AuthenticatedRoute path='/_/profile/albums/new' exact component={EditAlbum} props={childProps} />
    <AuthenticatedRoute path='/_/profile/albums/:id' exact component={EditAlbum} props={childProps} />
    <AuthenticatedRoute path='/_/profile/edit' exact component={EditProfile} props={childProps} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>
